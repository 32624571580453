import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

// 이메일을 유지할 것임
const initState = {
    email: ''
}

export const loginPostAsync = createAsyncThunk()

const loginSlice = createSlice({
    // 옵션 -> reducer, 금고에 저장하는 데이터가 있었는데 이를 하나로 합쳐서 slice로 만드는 것
    name: "loginSlice",
    initialState: initState,
    // reducers -> 입력값이 있고 그것에 대한 출력값이 있음
    //  - 입력값 : action
    //  - 출력값 : 앞으로 계속 유지해야할 데이터
    reducers: {
        login: (state, action) => {
            console.log("login..........", action)
            console.log(action.payload)
            console.log("---------------")
            return {email: action.payload.email}
        },
        logout: () => {
            console.log("logout..........")
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginPostAsync.fulfilled, (state, action) => {
            console.log('fulfilled')
        })
            .addCase(loginPostAsync.pending, (state, action) => {
                console.log('pending')
            })
            .addCase(loginPostAsync.rejected, (state, action) => {
                console.log('rejected')
            })
    }
})

// reducer로 만들어놓은 기능인 login, logout은 외부에서 사용할 수 호출할 수 있게끔
export const {login, logout} = loginSlice.actions

// reducer로 반환
export default loginSlice.reducer