import React from 'react';
import { BrowserRouter as Router, Route, Switch, RouterProvider } from 'react-router-dom';
import root from "./router/root"

// import Layout from './components/Layout';
// import MemberManagement from './pages/MemberManagement';
// import TradePermissionManagement from './pages/TradePermissionManagement';
// import CertificationMarkManagement from './pages/CertificationMarkManagement';
// import Home from './pages/Home';

const App = () => {
    return (
      <RouterProvider router={root}/>
        // <Router>
        //     <Layout>
        //         <Switch>
        //             {/* <Route exact path="/" component={Home} /> */}
        //             <Route path="/members" component={MemberManagement} />
        //             {/* <Route path="/trade-permissions" component={TradePermissionManagement} />
        //             <Route path="/certification-marks" component={CertificationMarkManagement} /> */}
        //         </Switch>
        //     </Layout>
        // </Router>
    );
}

export default App;