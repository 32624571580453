import { KeyIcon, LockClosedIcon, UserIcon } from '@heroicons/react/16/solid';
import React, {useEffect, useState} from 'react';
import logo from '../images/Logo.png'
import useCustomMove from "../hooks/useCustomMove";
import {login} from "../api/adminApi";
import {useCookies} from "react-cookie";
import {useTokenManager} from "../api/useTokenManager";

const initState = {
    email: '',
    password: ''
}

function LoginPage(props) {
    const [loginParam, setLoginParam] = useState(initState)
    const { moveToRegisterPage, moveToMemberList } = useCustomMove()
    const[cookies, removeCookie] = useCookies()
    const [rememberMe, setRememberMe] = useState(false)
    const { handleReissueToken } = useTokenManager()

    useEffect(() => {
        const token = cookies['accessToken']
        if(token) {
            moveToMemberList()
        } else {
            console.log('no token')
            const savedUsername = localStorage.getItem('rememberEmail')
            if(savedUsername) {
                loginParam.email = savedUsername
                setRememberMe(true)
            }
            // removeCookie("accessToken", {path: "/"})
            // removeCookie("refreshToken", {path: "/"})
            // removeCookie("role", {path: "/"})
            // removeCookie("name", {path: "/"})
        }
    }, []);

    const handleChange = (e) => {
        loginParam[e.target.name] = e.target.value
        setLoginParam({...loginParam})
    }

    const handleClickLogin = () => {
        if(loginParam.email === null || loginParam.email === '') {
            alert('이메일을 입력해주세요.')
            return
        }
        if(loginParam.password === null || loginParam.password === '') {
            alert('비밀번호를 입력해주세요.')
            return
        }
        login(loginParam).then(data => {
            const accessToken = data.headers['authorization']
            const refreshToken = data.headers['refresh']
            const role = data.headers['role']
            const name = data.data['name']

            console.log('accessToken:', accessToken)
            console.log('refreshToken:', refreshToken)
            console.log('role:', role)
            console.log('name:', name)

            handleReissueToken(accessToken, refreshToken, role, name)

            // 아이디 저정
            if(rememberMe) {
                localStorage.setItem('rememberEmail', loginParam.email)
            } else {
                localStorage.removeItem('rememberEmail')
            }

            moveToMemberList()
        }).catch(error => {
            if(error.response && error.response.data.status === 400) {
                // 관리자 승인
                alert('관리자 승인을 받은 후 로그인해주세요.')
            }
            if(error.response && error.response.data.status === 419) {
                // 아이디 또는 비밀번호 확인
                alert('아이디 또는 비밀번호를 확인해주세요.')
            } else {
                alert('잠시 후 다시 시도해주세요.')
            }
            console.log('Error Login:', error)
        })
    }

    const handleRememberMeChange = (event) => {
        setRememberMe(event.target.checked)
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-md">
                <div className="flex justify-center mb-6">
                    <img src={logo} />
                </div>
                <div className="text-center text-blue-500 font-bold mb-6">
                    의료기기 거래 플랫폼 Doctor Market!
                </div>
                <div className="flex justify-center mb-6">
                    <LockClosedIcon className="h-24 w-24 text-gray-500" />
                </div>
                <div className="space-y-4">
                    <div className="relative">
                        <UserIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 h-5 w-5" />
                        <input
                        type="text"
                        name="email"
                        placeholder="이메일"
                        className="pl-10 pr-3 py-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                        value={loginParam.email}
                        onChange={handleChange}
                        />
                    </div>
                    <div className="relative">
                        <KeyIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 h-5 w-5" />
                        <input
                        type="password"
                        name="password"
                        placeholder="비밀번호"
                        className="pl-10 pr-3 py-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                        value={loginParam.password}
                        onChange={handleChange}
                        />
                    </div>
                    <div className="flex items-center">
                        <input type="checkbox" id="rememberMe" className="mr-2"
                        checked={rememberMe} onChange={handleRememberMeChange}/>
                        <label htmlFor="rememberMe" className="text-sm text-gray-700">아이디 저장</label>
                    </div>
                    <div className="flex">
                        <button
                            type="submit"
                            className="w-1/2 bg-blue-500 text-white py-2 rounded-md mr-4 hover:bg-blue-700 focus:outline-none"
                            onClick={moveToRegisterPage}
                        >
                            회원가입
                        </button>
                        <button
                            type="submit"
                            className="w-1/2 bg-blue-500 text-white py-2 rounded-md hover:bg-blue-700 focus:outline-none"
                            // onClick={moveToPage}
                            onClick={handleClickLogin}
                        >
                            로그인
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;